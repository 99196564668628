import React from 'react'

const CrearPodcast = () => {
    return (
        <div>
            Podcast!
        </div>
    )
}

export default CrearPodcast
